/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// @import "./assets/sass/style";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import "./assets/sass/plugins.scss";
@import "./assets/sass/style.angular.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

:root {
  --popup-opacity: 0;
  --popup-visibility: hidden;
  --popup-ft_content-opacity: 0;
  --popup-ft_content-transform: translate(-50%, -50%) scale(0.25);
  --popup-height: 10rem;
  --popup-width: 10rem;
}
